<template>
    <button class="apr-show-more-btn">
        <span>{{ btnText }}</span>
        <NuxtIcon name="ri:arrow-down-s-line" :class="{ 'rotate-180': isOpened }" />
    </button>
</template>

<script setup lang="ts">
const props = defineProps({
    isOpened: { type: Boolean, default: false },
    showText: { type: String },
    hideText: { type: String },
});

const btnText = computed(() => (props.isOpened ? props.hideText : props.showText));
</script>

<style lang="postcss" scoped>
.apr-show-more-btn {
    @apply inline-flex items-center gap-2 text-link text-base font-semibold;
}
</style>
